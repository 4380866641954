import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";

import DashboardLoader from "../../components/DashboardContent/DashboardLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import useAdminCtx from "../../hooks/useAdminCtx";
import { deleteHomePageSection, getAllCategoriesBanenrs, getHomePageSections } from "../../api/AdminApis/HomePageSections";

import "./styles/dashboardProducts.css";
import "./styles/DashboardHomePage.css";
import { categoriesList2 } from "../../utils/Constants";
import { Autocomplete, TextField } from "@mui/material";
import CategoryPageAdminPannel from "./CategoryPageAdminPannel";
import HomePageAdminPannel from "./HomePageAdminPannel";
import HomePageStaticPosters from "./HomePageStaticPosters";
import TopBannerSequesnce from "./TopBannerSequesnce";
import SmartPhoneBanner from "./SmartPhoneBanner";

// Component definition
function DashboardHomePage() {
  const navigate = useNavigate();
  const location = useLocation();

  // state definitions
  const [loader] = useState(false);
  const [allBottomSections, setAllBottomSections] = useState([]);
  const [homePageSectionArr, setHomePageSectionArr] = useState([]);
  const [categoryPageSectionArr, setCategoryPageSectionArr] = useState([]);
  const [smartPhoneBanners, setSmartPhoneBanners] = useState([]);
  const [brandPageBanners, setBrandPageBanners] = useState([]);

  const [categoryName, setCategoryName] = useState("");
  const { allHomePageSections, setAllHomePageSections } = useAdminCtx();

  const handleDelete = (id) => {
    if (!window.confirm("Are you sure?")) return;

    deleteHomePageSection(id, (err, res) => {
      if (err) return toast.error("Something went wrong!");
      getHomePageSections((err, res) => {
        if (err) return console.log(err);
        setAllHomePageSections(res.sections);
      });
    });
  };

  const handleNavigate = (type) => {
    navigate("../admin-home-add-section", {
      state: { type: type, from: location.pathname },
    });
  };

  useEffect(() => {
    getHomePageSections((err, res) => {
      if (err) return console.log(err);
      setAllHomePageSections(res.sections);
    });
  }, []);

  useEffect(() => {
    setAllBottomSections(allHomePageSections.filter((item) => item.sectionType !== "scroll_banner"));
    if (allHomePageSections) {
      var homepageArr = allHomePageSections.filter((item) => item.sectionType !== "scroll_banner" && item.sectionType !== "static_poster" && item.sectionType !== "scroll_banner_smartPhone");
      homepageArr = homepageArr.filter((item) => (item.sectionType === "static_deals" ? !item.sectionHeading.includes("_") : true));
      setHomePageSectionArr(homepageArr);
    }
    if (allHomePageSections) {
      var categoryArr = allHomePageSections.filter((item) => item.sectionType !== "scroll_banner");
      categoryArr = categoryArr.filter((item) => item.sectionType === "static_deals" && item.sectionHeading.includes("_"));
      setCategoryPageSectionArr(categoryArr);
      var smartPhoneArr = allHomePageSections.filter((item) => item.sectionType === "scroll_banner_smartPhone" && item?.sectionHeading?.split("_")[1] === "HOMEPAGE");
      setSmartPhoneBanners(smartPhoneArr);
    }
  }, [allHomePageSections]);

  const handleSelectedCategory = (e, value) => {
    if (value) {
      setCategoryName(value[0]);
      const homePageSections = [...allHomePageSections];
      let filtresCategoryBanners = homePageSections.filter(
        (section) => section.sectionType === "static_deals" && section.sectionHeading.split("_")[1] === value[0] && section.sectionType !== "scroll_banner"
      );
      setCategoryPageSectionArr(filtresCategoryBanners);
    } else {
      if (allHomePageSections) {
        var categoryArr = allHomePageSections.filter((item) => item.sectionType !== "scroll_banner");
        categoryArr = categoryArr.filter((item) => item.sectionType === "static_deals" && item.sectionHeading.includes("_"));
        setCategoryPageSectionArr(categoryArr);
      }
      setCategoryName("");
    }
  };

  const [activeTab, setActiveTab] = useState("homepage");

  return loader ? (
    <DashboardLoader />
  ) : (
    <>
      <div className="container " style={{ fontSize: "small" }}>
        <ul className="nav nav-tabs mt-2">
          <li className="nav-item">
            <a className={`nav-link ${activeTab === "homepage" ? "active" : ""}`} onClick={() => setActiveTab("homepage")}>
              Home Page
            </a>
          </li>
          <li className="nav-item">
            <a className={`nav-link ${activeTab === "categoryPage" ? "active" : ""}`} onClick={() => setActiveTab("categoryPage")}>
              Category Page
            </a>
          </li>
          <li className="nav-item">
            <a className={`nav-link ${activeTab === "brandPage" ? "active" : ""}`} onClick={() => setActiveTab("brandPage")}>
              Brand Page
            </a>
          </li>
          <li className="nav-item">
            <a className={`nav-link ${activeTab === "BannerSequence" ? "active" : ""}`} onClick={() => setActiveTab("BannerSequence")}>
              All Top Banner
            </a>
          </li>
          <li className="nav-item">
            <a className={`nav-link ${activeTab === "smartPhoneBanner" ? "active" : ""}`} onClick={() => setActiveTab("smartPhoneBanner")}>
              Mobile View Banners
            </a>
          </li>
          &nbsp;&nbsp;&nbsp;&nbsp;
          {activeTab === "categoryPage" && (
            <div className="d-flex w-100 mt-2">
              <div className="col-lg-4 ">
                <Autocomplete
                  id="statusSelected"
                  className="form-control p-0"
                  style={{ marginBottom: "0px" }}
                  size="medium"
                  options={categoriesList2}
                  onChange={(e, value) => handleSelectedCategory(e, value)}
                  getOptionLabel={(option) => option[0] || ""}
                  value={categoryName !== "" ? [categoryName] : null}
                  clearOnEscape={false}
                  renderInput={(params) => <TextField aria-expanded="false" {...params} variant="standard" placeholder="Select Category" />}
                />
              </div>
            </div>
          )}
          <div style={{ position: "absolute", right: "10%" }}>
            <Dropdown>
              <Dropdown.Toggle variant="info" id="dropdown-basic">
                Add New Section
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {/* <Dropdown.Item onClick={() => handleNavigate("scroll_banner")}>Scroll Banner</Dropdown.Item> */}
                <Dropdown.Item onClick={() => handleNavigate("static_banner")}>Static Banner</Dropdown.Item>
                <Dropdown.Item onClick={() => handleNavigate("scroll_card")}>Scroll Card</Dropdown.Item>
                {/* <Dropdown.Item onClick={() => handleNavigate("static_card")}>Static Card</Dropdown.Item> */}
                <Dropdown.Item onClick={() => handleNavigate("static_deals")}>Static Deals/Offers</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </ul>

        {(activeTab === "homepage" || activeTab === "categoryPage") && (
          <div
            className="top-scroll-banners-section mt-2 cursor-pointer"
            onClick={() =>
              navigate("/admin/admin-home-page/top-banners", {
                state: {
                  type: `${activeTab === "homepage" ? "homepage" : "categoryPage"}`,
                  from: location.pathname,
                },
              })
            }
          >
            <span>Manage Top {activeTab === "homepage" ? "Home" : "Category"} Scrolling Banners</span>
            <FontAwesomeIcon cursor={"pointer"} icon={faArrowRight} />
          </div>
        )}

        <div className="tab-content mt-3">
          {/* Tab 1: All Orders */}
          <div id="homepage" className={`tab-pane fade ${activeTab === "homepage" ? "show active" : ""}`}>
            <HomePageAdminPannel handleDelete={handleDelete} allBottomSections={homePageSectionArr} />
          </div>

          {/* Tab 2: Delivered Orders */}
          <div id="categoryPage" className={`tab-pane fade ${activeTab === "categoryPage" ? "show active" : ""}`}>
            <CategoryPageAdminPannel handleSelectedCategory={handleSelectedCategory} handleDelete={handleDelete} categoryName={categoryName} allBottomSections={categoryPageSectionArr} />
          </div>
          <div id="brandPage" className={`tab-pane fade ${activeTab === "brandPage" ? "show active" : ""}`}>
            <HomePageStaticPosters activeTab={activeTab} />
          </div>
          <div id="BannerSequence" className={`tab-pane fade ${activeTab === "BannerSequence" ? "show active" : ""}`}>
            <TopBannerSequesnce activeTab={activeTab} />
          </div>
          <div id="smartPhoneBanner" className={`tab-pane fade ${activeTab === "smartPhoneBanner" ? "show active" : ""}`}>
            <SmartPhoneBanner smartPhoneBanners={smartPhoneBanners} />
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardHomePage;
