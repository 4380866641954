import React from "react";
import "./Banners.css";
import { Link } from "react-router-dom";

const Banners = (banners) => {
  const handleNavigate = (link) => {
    window.location.href = link;
  };
  return (
    <div className="banner-component">
      {banners.banners.length > 0 && (
        <>
          <div className="banner mobilescreen flex-row" style={{ paddingRight: "5px", paddingLeft: "5px" }}>
            {banners.banners.slice(0, 3).map((poster, index) => (
              <Link to={poster.targetUrl} key={index}>
                <img className="bannerimg" src={poster.imgUrl} alt="Apple Airpods Pro Mobile" />
              </Link>
            ))}
          </div>

          <div className="banner desktop" style={{ width: "100%" }}>
            {banners.banners.map((poster, index) => (
              <img className="bannerimg" key={index} src={poster.imgUrl} alt={poster.title} onClick={() => handleNavigate(poster.targetUrl)} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Banners;
