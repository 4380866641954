import React, { useEffect } from "react";
import { ListGroup } from "react-bootstrap";
import DashboardLoader from "../DashboardContent/DashboardLoader";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { currencyFormat, formatDate } from "../../utils";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

function extractKeyValues(obj, prefix = "") {
  let result = {};

  for (let key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      // Recursively extract nested objects
      const nestedResult = extractKeyValues(obj[key], prefix + key + ".");
      result = { ...result, ...nestedResult };
    } else {
      result[prefix + key] = obj[key];
    }
  }

  return result;
}

function downloadDataAsExcel(data, bookName, fileName) {
  // Create a new workbook
  const wb = XLSX.utils.book_new();
  const filename = `${fileName}.xlsx`;
  // Convert the data to a worksheet
  const ws = XLSX.utils.json_to_sheet([data]);

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(wb, ws, bookName);
  XLSX.writeFile(wb, filename);
}

const AdminConsignmentDetailsModal = (props) => {
  const { details, consignmentSelected, loading = false } = props;
  const productIndex = details?.order?.productDetails ? details?.order?.productDetails.findIndex((ele) => details.product._id?.toString() === ele?.productId?.toString()) : 1;
  const onsitegoData = details?.onsitego_data;
  let onsitegoPlans = [];
  const Plans = details?.onsitego_data.forEach((element) => {
    onsitegoPlans.push(element.plans[0]);
  });
  function downloadExcelForAWBDetails() {
    try {
      const shiprocket_awb_details = details?.shiprocket_awb_details;
      if (!shiprocket_awb_details) {
        toast.error("Oops!!! Can't Download the Excel");
        return;
      }
      const jsonObject = JSON.parse(shiprocket_awb_details);

      const extractedData = extractKeyValues(jsonObject);

      downloadDataAsExcel(extractedData, "ShipRocket AWB Details", "Shiprocket_AWB_Details");
    } catch (error) {
      console.error("Error parsing JSON:", error);
      toast.error("Oops!!! Can't Download the Excel for AWB Details");
    }
  }

  function downloadExcelForPickupDetails() {
    try {
      const shiprocket_pickup_details = details?.shiprocket_pickup_details;
      if (!shiprocket_pickup_details) {
        toast.error("Oops!!! Can't Download the Excel");
        return;
      }
      const jsonObject = JSON.parse(shiprocket_pickup_details);
      const extractedData = extractKeyValues(jsonObject);

      downloadDataAsExcel(extractedData, "ShipRocket Pickup Details", "Shiprocket_Pickup_Details");
    } catch (error) {
      console.error("Error parsing JSON:", error);
      toast.error("Oops!!! Can't Download the Excel for Pickup Details");
    }
  }

  const navigate = useNavigate();

  const handleCreateInvoice = () => {
    const queryParams = new URLSearchParams();
    queryParams.set("id", consignmentSelected);
    queryParams.set("from", "Admin");
    const url = `/invoice?${queryParams.toString()}`;
    const newWindow = window.open(url, "_blank");
    if (!newWindow) {
      // Fallback to navigating in the same tab if opening in new tab is blocked
      navigate("/invoice");
    }
  };

  return (
    <Dialog open={props.show} onClose={props.onHide} scroll={"body"} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description" className="order-dialog-box">
      {loading ? (
        <DashboardLoader />
      ) : (
        <>
          <DialogTitle id="scroll-dialog-title">
            Consignment Details for <span className="fw-bold">{consignmentSelected}</span>
          </DialogTitle>
          <DialogContent
            style={{
              overflowY: "initial",
            }}
          >
            <DialogContentText>
              <div className="row ">
                <p className="fw-bold fa-lg">Product Details</p>
                <ListGroup as="ol">
                  {details?.order?.productDetails?.length === 0 || details?.order?.productId?.length === 0 || details?.order?.item?.length === 0 ? (
                    <p className="d-flex justify-content-center align-items-center p-3">Product Details Not Available</p>
                  ) : (
                    <table className="table table-bordered" style={{ width: "100%" }}>
                      <thead className="tHead">
                        <tr>
                          <td style={{ width: "10%" }}>Image</td>
                          <td style={{ width: "40%" }}>Product</td>
                          <td style={{ width: "10%" }}>Unit Price</td>
                          <td style={{ width: "10%" }}>Quantity</td>
                          <td style={{ width: "10%" }}>Total Amount</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ alignContent: "center" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                className=" rounded-3"
                                style={{ width: "100px", height: "70px", objectFit: "contain" }}
                                alt="product-1image"
                                src={details?.order?.productDetails[productIndex]?.image}
                              />
                            </div>
                          </td>
                          <td style={{ alignContent: "center", width: "300px" }}>
                            <div className="d-flex flex-wrap text-justify text-wrap flex-column">
                              <div>
                                <span className="prodTitle">{details?.order?.productDetails[productIndex]?.name}</span>
                              </div>
                            </div>
                          </td>
                          <td style={{ alignContent: "center" }}>
                            <span className="perProductPrice">{currencyFormat(details?.order?.productDetails[productIndex]?.selling_price)}</span>
                          </td>
                          <td style={{ alignContent: "center" }}>
                            <span>{details?.order?.item[productIndex]}</span>
                          </td>
                          <td style={{ alignContent: "center" }}>
                            <span className="perProductPrice">{currencyFormat(details?.order?.totalPrice)}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </ListGroup>
              </div>
              {details?.combo && (
                <div className="row my-3">
                  <ListGroup as="ol">
                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                      Combo Product EAN : <span className="fw-bold">{details?.combo?.childEan}</span>
                    </ListGroup.Item>
                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                      Combo Product Name: <span className="fw-bold">{details?.combo?.product?.title}</span>
                    </ListGroup.Item>
                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                      Combo Voucher ID
                      {details?.comboVoucherId ? (
                        <span
                          className="fw-bold "
                          // onClick={() => {
                          //   window.open(`${process.env.REACT_APP_PDFURL}/${details.comboVoucherId}.pdf`, "_blank");
                          // }}
                        >
                          {details.comboVoucherId}
                        </span>
                      ) : (
                        "-"
                      )}
                    </ListGroup.Item>
                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                      Combo Voucher Generation Time: <span className="fw-bold">{details?.comboVoucherGenerationTime ? formatDate(details.comboVoucherGenerationTime) : "-"}</span>
                    </ListGroup.Item>
                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                      Combo Product Discount Type: <span className="fw-bold">{details?.combo?.discountType}</span>
                    </ListGroup.Item>
                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                      Combo Product Discount Value <span className="fw-bold">{details?.combo?.value}</span>
                    </ListGroup.Item>
                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                      Combo Product Batch Name <span className="fw-bold">{details?.combo_batch_name}</span>
                    </ListGroup.Item>
                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                      Combo Product Price <span className="fw-bold">{currencyFormat(details?.combo_child_price)}</span>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              )}
              <div className="row my-3">
                <ListGroup as="ol">
                  <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                    Coupon Applied : <span className="fw-bold">{details?.couponeId?.code ?? "-"}</span>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                    Coupon Amount : <span className="fw-bold">{details?.couponeId?.value ? currencyFormat(details?.couponeId?.value) : "-"}</span>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                    Consignment ID: <span className="fw-bold">{consignmentSelected}</span>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                    Consignment Status: <span className="fw-bold">{details?.status}</span>
                  </ListGroup.Item>
                  {details?.status !== "INITIATED" && details.acceptedAt && (
                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                      Consignment Accepted At:{" "}
                      <span className="fw-bold">
                        {new Intl.DateTimeFormat("en-IN", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                          hour12: true,
                          timeZone: "Asia/Calcutta",
                        }).format(new Date(details.acceptedAt))}
                      </span>
                    </ListGroup.Item>
                  )}
                  {details?.status === "DELIVERY_SUCCESS" && details?.delivered_at && (
                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                      Consignment Delivered At:{" "}
                      <span className="fw-bold">
                        {new Intl.DateTimeFormat("en-IN", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                          hour12: true,
                          timeZone: "Asia/Calcutta",
                        }).format(new Date(details.delivered_at))}
                      </span>
                    </ListGroup.Item>
                  )}
                  <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                    Was Escalated: <span className="fw-bold">{details?.is_escalated ? "Yes" : "No"}</span>
                  </ListGroup.Item>
                </ListGroup>
              </div>

              {details?.cancelled_at && (
                <div className="row my-3">
                  <ListGroup as="ol">
                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                      Cancelled By: <span className="text-uppercase fw-bold">{details?.cancelled_by}</span>
                    </ListGroup.Item>
                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                      Cancelled At{" "}
                      <span className="fw-bold">
                        {new Intl.DateTimeFormat("en-IN", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                          hour12: true,
                          timeZone: "Asia/Calcutta",
                        }).format(new Date(details?.cancelled_at))}
                      </span>
                    </ListGroup.Item>
                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                      Cancel Reason: <span className="fw-bold">{details?.cancel_reason}</span>
                    </ListGroup.Item>
                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                      Credit Note ID: <span className="fw-bold">{details?.creditNoteId}</span>
                    </ListGroup.Item>
                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                      Credit Note Description: <span className="fw-bold">{details?.creditNoteDescription}</span>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              )}
              {details?.status !== "INITIATED" && (
                <>
                  <div className="row my-3">
                    <ListGroup as="ol">
                      <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                        Store Name <span className="fw-bold">{details?.consignor?.fc_name ?? "-"}</span>
                      </ListGroup.Item>
                      <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                        Brand Store ID: <span className="fw-bold">{details?.consignor?.brand_store_id ?? "-"}</span>
                      </ListGroup.Item>
                      <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                        Batch Name: <span className="fw-bold">{details?.batch_name ?? "-"}</span>
                      </ListGroup.Item>
                    </ListGroup>
                  </div>
                  <div className="row my-3">
                    <ListGroup as="ol">
                      <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                        Voucher ID
                        {details?.voucherId ? (
                          <span
                            className="fw-bold voucher-ids"
                            onClick={
                              () => handleCreateInvoice()

                              // window.open(`${process.env.REACT_APP_PDFURL}/${details.voucherId}.pdf`, "_blank");
                            }
                          >
                            {details.voucherId}
                          </span>
                        ) : (
                          "-"
                        )}
                      </ListGroup.Item>
                      <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                        Voucher Generation Time: <span className="fw-bold">{details?.voucherGenerationTime ? formatDate(details.voucherGenerationTime) : "-"}</span>
                      </ListGroup.Item>
                      <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                        User GST Claim: <span className="fw-bold">{details?.claimGst ? "Yes" : "No"}</span>
                      </ListGroup.Item>
                    </ListGroup>
                  </div>
                  <div className="row my-3">
                    <ListGroup as="ol">
                      <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                        Shiprocket Order ID: <span className="fw-bold">{details?.shiprocket_order_id ?? "-"}</span>
                      </ListGroup.Item>
                      <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                        Shiprocket Shipment ID: <span className="fw-bold">{details?.shiprocket_shipment_id ?? "-"}</span>
                      </ListGroup.Item>
                      <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                        Shiprocket AWB Details:{" "}
                        <span onClick={downloadExcelForAWBDetails} className="fw-bold text-decoration-underline cursor-pointer">
                          {"Download Excel"}
                        </span>
                      </ListGroup.Item>
                      <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                        Shiprocket Pickup Details:{" "}
                        <span onClick={downloadExcelForPickupDetails} className="fw-bold text-decoration-underline cursor-pointer">
                          {"Download Excel"}
                        </span>
                      </ListGroup.Item>
                    </ListGroup>
                  </div>
                  {onsitegoData && (
                    <div className="row my-3">
                      <ListGroup as="ol">
                        {onsitegoData.map((item, i) => (
                          <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                            OnsiteGo Plan Invoice Data: <span className="fw-bold">{item?.plan_invoice_number ?? "-"}</span>
                          </ListGroup.Item>
                        ))}
                        {onsitegoPlans?.length > 0 &&
                          onsitegoPlans?.map((data, i) => (
                            <ListGroup horizontal key={`onsitego_${i}`} className="d-flex justify-content-between align-items-center">
                              <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                OnsiteGo Plan ID: <span className="fw-bold">{data?.plan_id ?? "-"}</span>
                              </ListGroup.Item>
                              <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                OnsiteGo OSID: <span className="fw-bold">{data?.osid ?? "-"}</span>
                              </ListGroup.Item>
                              <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                OnsiteGo Certificate:{" "}
                                <span className="fw-bold">
                                  {data?.certificate ? (
                                    <a target="_blank" rel="noreferrer" href={data?.certificate?.toString()?.replace("http:", "https:")}>
                                      Download Link
                                    </a>
                                  ) : (
                                    "-"
                                  )}
                                </span>
                              </ListGroup.Item>
                            </ListGroup>
                          ))}
                      </ListGroup>
                    </div>
                  )}
                </>
              )}
            </DialogContentText>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default AdminConsignmentDetailsModal;
