import React, { useCallback, useEffect, useMemo, useState } from "react";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import "./ProductCard.css";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { currencyFormat } from "../../utils";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import useUserCtx from "../../hooks/useUserCtx";
import NothingImage from "../../assets/png/your_order_image.png";
import Swal from "sweetalert2";

import { API_RESPONSE_STATUS, DEFAULT_GST_PERCENTAGE, PRODUCT_CLASSIFICATION } from "../../utils/Constants";
// APIs
import { getSearchedProduct, checkProdServiceabilityStatus, getIndiProduct, getUsersSubProds, unsubsAProduct, storeProdNotificationService, preBookProd, refundPreBookProd } from "../../api/Product";
import { addToCart, getCartData, getOnsiteGoProd, removeAllFromCart, removeFromCart } from "../../api/Cart";
import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { addToWishlist, deleteFromWishlist, getAllWishlistItems } from "../../api/wishlistApi";
import flag from "../../assets/png/Flag.png";

const ProductCard = ({ id, productImg, productName, productDescription, productRating, productPriceObj, productDiscountObj, productSlug, wishlist, cart, bestSeller, classification, preBookData }) => {
  const { userContext, setCartArray, setOrderInit, userZip, cartArray, setUserWishlist, setUserComboCart, setPaymentProductArr, userSubscribed, setUserSubscribed, setUserPreBooked, userPreBooked } =
    useUserCtx();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:500px)");
  const { t } = useTranslation();

  const [isLoadingBuyNow, setIsLoadingBuyNow] = useState(false);

  // Computed
  const maxMRPPrice = productPriceObj?.mrp ? productPriceObj?.mrp : 0;

  const getDiscount = useMemo(() => {
    if (productPriceObj) {
      let productPrice = productPriceObj?.mop;

      let price = productPriceObj?.mrp;
      let discountPercentage = 0;

      // deduct flat discount
      if (productDiscountObj?.flatDiscount?.value > 0) {
        if (productDiscountObj?.flatDiscount?.discountType === "flat") {
          productPrice -= productDiscountObj?.flatDiscount?.value;
        } else {
          productPrice -= (productPrice * productDiscountObj?.flatDiscount?.value) / 100;
        }
      }
      if (price !== productPrice) {
        discountPercentage = Math.round(((price - productPrice) / price) * 100);
      }
      let prebookedAmount = 0;
      if (userPreBooked.length > 0) {
        prebookedAmount = userPreBooked.filter((el) => el.productId === id).map((el) => el.amount);

        if (productPrice > 0) {
          productPrice -= prebookedAmount;
        }
      }

      return {
        discountPrice: productPrice,
        discountPercentage,
      };
    }
    return { discountPercentage: 0 };
  }, [productPriceObj, productDiscountObj, userPreBooked]);

  const setCartFunc = () => {
    getCartData().then((res) => {
      if (res) {
        let prod = [];
        res.cart.forEach((item) => {
          if (item?.productId === null) return;
          let product = { ...item?.productId };
          product["quantity"] = item.qty;
          product["from"] = item.from;
          product["coupon"] = item.couponId ? item.couponId : {};
          prod.push(product);
        });
        setCartArray({
          loaded: true,
          no_of_carts: prod.length,
          cart: prod,
          combo: res.combo,
        });
      }
    });
  };

  const handleAddToCart = async (id, fromCart = true) => {
    let userToken = userContext ? userContext.JWT : "";
    if (userToken) {
      let brand_store_id = "";

      const product = await getIndiProduct(id);

      if (location.pathname.split("/")[1] === "store") {
        brand_store_id = location.pathname.split("/")[2];
      }

      addToCart({
        id,
        qty: 1,
        brand_store_id,
        onsiteGoSelected: product?.hierarchyL2 === "Smartphone" ? (process.env.REACT_APP_BASE_URL === "https://alpha-api.olineo.in/api" ? [18588] : [3507]) : null,
      }).then((res) => {
        if (res?.data?.status === "error") {
          if (window.confirm(res.data.message)) {
            removeAllFromCart().then((response) => {
              if (response) {
                setCartFunc();
                setOrderInit((prev) => ({ ...prev, type: brand_store_id ? "Manual" : "", brand_store_id }));
                toast.success(t("manualAddToCart"));
                setIsLoadingBuyNow(false);
              }
            });
          }
        } else if (res?.data?.status === "Success") {
          setCartFunc();
          setIsLoadingBuyNow(false);
          if (fromCart) {
            toast.success(t("product-page.added-to-cart"));
          } else navigate("/payment");
        } else toast.error(t("writeToUs.error"));
      });
    } else {
      navigate("/login");
    }
  };

  // check whether prod is serviceable and then adds it to cart
  const prepareAddToCart = async (productId) => {
    // if (!userZip.value) {
    //   toast.error(t("NoUserPincode"));
    //   return;
    // }
    const userToken = userContext ? userContext.JWT : "";
    if (userToken) {
      setIsLoadingBuyNow(true);
      try {
        const res = await getSearchedProduct("slug=" + productSlug);
        let product = res.products[0];
        if (!product || product.classification === PRODUCT_CLASSIFICATION.TEMP_HIDDEN) {
          navigate("/not-found");
        } else {
          product["quantity"] = 1;
        }

        if (product && (await checkThisProductServiceability(product))) {
          handleAddToCart(productId, true);
        }
      } finally {
        setIsLoadingBuyNow(false);
      }
    } else {
      let updatedCartArr;

      const prevCartArr = JSON.parse(localStorage.getItem("cart"));
      const product = await getIndiProduct(id);

      let images = product?.images ?? [];
      if (product) product["quantity"] = 1;
      product.from = location.state ? [location.state?.brand_store_id] : [];

      //Product Heading
      let heading = product.title;

      const productData = {
        product_loaded: true,
        product_L1: product.hierarchyL1,
        product_L2: product.hierarchyL2,
        product_L3: product.hierarchyL3,
        product_Classification: product.classification,
        product_Id: product._id,
        product_Ean: product.ean,
        product_Heading: heading,
        product_name: product.title,
        product_color: product.color || product.title?.toString()?.split("-")?.pop()?.trim(),
        product_price: product.price,
        product_Description: product.description[0],
        product_image_List: images,
        product_Gallery_Image: [...product.gallery],
        product_Discount: product.discount,
        product_Slug: product.slug,
        product_Instock: product.qty,
        product_brand: product.brand,
        gstpercentage: product?.gstpercentage ?? DEFAULT_GST_PERCENTAGE,
        warranty: product?.productInfo?.warranty,
        productInfo: product?.productInfo,
        _id: product?._id,
      };

      let isAlreadyAdded = false;
      if (prevCartArr.cart) {
        prevCartArr.cart.forEach((prod) => {
          if (prod.product_Ean === productData.product_Ean) {
            isAlreadyAdded = true;
            return;
          }
        });
      }
      if (!isAlreadyAdded) {
        productData.quantity = 1;
        productData.from = [];
        updatedCartArr = {
          ...prevCartArr,
          cart: [...prevCartArr.cart, productData],
          no_of_carts: prevCartArr.no_of_carts + 1,
          loaded: true,
        };
        // Save the updated prevCartArr back to local storage
        localStorage.setItem("cart", JSON.stringify(updatedCartArr));

        setCartArray(() => ({
          loaded: true,
          no_of_carts: updatedCartArr.cart.length,
          cart: updatedCartArr.cart,
          combo: [],
        }));
      }
      setIsLoadingBuyNow(false);
    }
  };

  const handleRemoveFromCart = (id) => {
    if (userContext && userContext?.JWT) {
      removeFromCart(id).then((res) => (res ? (setUserComboCart([]), toast.error(t("cart.remove-from-cart")), setCartFunc()) : ""));
    } else {
      let updatedCartArr;

      let isAlreadyAdded = false;
      if (cartArray.cart) {
        cartArray.cart.forEach((prod, index) => {
          if (prod._id === id) {
            isAlreadyAdded = true;
            cartArray.cart.splice(index, 1);
            return;
          }
        });
      }
      if (isAlreadyAdded) {
        updatedCartArr = {
          ...cartArray,
          no_of_carts: cartArray.no_of_carts - 1,
          loaded: true,
        };
        // Save the updated prevCartArr back to local storage
        localStorage.setItem("cart", JSON.stringify(updatedCartArr));

        setCartArray(() => ({
          loaded: true,
          no_of_carts: updatedCartArr.cart.length,
          cart: updatedCartArr.cart,
          combo: [],
        }));
      }
    }
  };

  // check whether the product is serviceable to the user
  const checkThisProductServiceability = async (product) => {
    let isProductServiceable = false;
    const pincode = userZip.value;
    if (product.ean) {
      const item = {
        ean: product.ean,
        title: product.title,
        quantity: 1,
      };

      const response = await checkProdServiceabilityStatus(item, pincode);
      const payload = response.data;
      if (payload.status === "success") {
        isProductServiceable = payload.data;
      } else {
        toast.error(t("productNotServicable"));
      }
    }

    return isProductServiceable;
  };

  const handleBuyNow = async (id) => {
    const userToken = userContext ? userContext.JWT : "";
    if (userToken) {
      setIsLoadingBuyNow(true);
      const product = await getIndiProduct(id);
      if (!userZip.value) {
        toast.error(t("NoUserPincode"));
        setIsLoadingBuyNow(false);
        return;
      }
      if (!(await checkThisProductServiceability(product))) {
        toast.error(t("productNotServicable"));
        setIsLoadingBuyNow(false);
        return;
      }
      let paymentProducts = [product];
      if (product.hierarchyL2 === "Smartphone") {
        try {
          const res = await getOnsiteGoProd(process.env.REACT_APP_BASE_URL === "https://alpha-api.olineo.in/api" ? 18588 : 3507);

          if (res) {
            // const planData = onsitegoData.find((item) => item.onsitego_sku === 3507); // Assuming you have the selectedSku

            res.plan.quantity = 1;
            res.plan.from = {
              product: product._id,
              plan: process.env.REACT_APP_BASE_URL === "https://alpha-api.olineo.in/api" ? 18588 : 3507, // Use the actual selectedSku if available
              planAmount: 299,
              planTitle: "Extended Warranty",
            };

            paymentProducts.push(res.plan);
          }
        } catch (err) {
          console.log("Error fetching onsitego product:", err);
        }
      }
      let productId = id;
      let quantity = [1];

      let onsiteGoPlan = [];
      product.quantity = 1;
      localStorage.setItem("paymentProductArr", JSON.stringify({ loaded: true, cart: paymentProducts, combo: [] }));

      setOrderInit((prev) => ({
        ...prev,
        productId: [productId],
        quantity: quantity,
        onsiteGoPlan,
      }));
      try {
        const res = await getSearchedProduct("slug=" + productSlug);
        let product = res.products[0];
        if (!product || product.classification === PRODUCT_CLASSIFICATION.TEMP_HIDDEN) {
          navigate("/not-found");
        } else {
          product["quantity"] = 1;
        }
        navigate("/payment");
        // if (product && (await checkThisProductServiceability(product))) {
        //   handleAddToCart(productId, false);
        // }
      } finally {
        setIsLoadingBuyNow(false);
      }
    } else {
      navigate("/login");
    }
  };

  const from = useMemo(() => {
    const arr = location.pathname.split("/");
    const type = location.pathname.split("/")[1] === "store";
    return {
      brand_store_id: type ? arr[2] : "",
      type: type ? "Manual" : "",
    };
  }, [location]);

  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: "#FFCC0A",
    },
    "& .MuiRating-iconHover": {
      color: "#FFCC0A",
    },
  });

  const handleAddToWishlist = useCallback((id, wishlist) => {
    let userToken = userContext ? userContext?.JWT : "";
    if (userToken) {
      if (!wishlist) {
        addToWishlist(id).then((res) => {
          if (res.status === 200) {
            toast.success(t("product-page.added-to-wishlist"));
            getAllWishlistItems(true).then((response) => {
              if (response)
                setUserWishlist((prev) => ({
                  ...prev,
                  loaded: true,
                  no_of_wishlist_items: response?.no_of_wishlist_items,
                  wishlist_items: response?.wishlist_items,
                }));
            });
          } else toast.error(t("addProductInWishlistError"));
        });
      } else {
        deleteFromWishlist(id).then((res) => {
          if (res.status === 200) {
            toast.error(t("notifications.ProductRemovedFromWishlist"));
            getAllWishlistItems(true).then((response) => {
              if (response)
                setUserWishlist((prev) => ({
                  ...prev,
                  loaded: true,
                  no_of_wishlist_items: response?.no_of_wishlist_items,
                  wishlist_items: response?.wishlist_items,
                }));
            });
          } else toast.error(t("deleteProductInWishlistError"));
        });
      }
    } else {
      navigate("/login");
    }
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const handleLinkClick = () => {
    // e.preventDefault();
    const url = `/product/${productSlug}`;

    // Open the link in a new tab
    window.open(url, "_blank");
    // if (newTab) {
    //   // Optionally, scroll to top when the new tab is opened
    //   handleScrollToTop();
    // } else {
    //   // Fallback to navigate in the same tab if the new tab was blocked
    //   navigate(url, { state: { ...from } });
    // }
  };

  const [thisProdSubs, setThisProdSubs] = useState(false);
  const [thisProdPreBooked, setThisProdPreBooked] = useState(false);
  const [subscribeLoading, setSubscribeLoading] = useState(false);

  const [preBookedId, setPreBookedId] = useState();

  // useEffect(() => {
  //   fetchUserSubscribedProds();
  // }, [userContext]);

  useEffect(() => {
    let subsresult = false;
    let prebookresult = false;
    let preBookId;
    if (userSubscribed.length > 0) {
      subsresult = userSubscribed.some((el) => el.productId === id);
    }
    if (userPreBooked.length > 0) {
      prebookresult = userPreBooked.some((el) => el.productId === id);
      preBookId = userPreBooked.find((el) => el.productId === id)?.prebookOrderId;
    }

    setPreBookedId(preBookId);
    setThisProdSubs(subsresult);
    setThisProdPreBooked(prebookresult);
  }, [userSubscribed, id, userPreBooked]);

  const notifyUserInit = () => {
    const userToken = userContext ? userContext.JWT : "";
    // const { id, productSlug } = productData;

    // product already subscribed
    if (thisProdSubs) {
      // remove product from user subscription list
      userProdUnsubs(id);
    } else {
      if (!userToken) {
        nav("/login", {
          state: {
            productId: id,
            notifyUser: true,
            productSlug: productSlug,
          },
        });
      } else {
        console.log("store Product notification");
        storeProductNotification(id);
      }
    }
  };

  const nav = useNavigate();
  const userProdUnsubs = async (prodId) => {
    try {
      setSubscribeLoading(true);
      const response = await unsubsAProduct(prodId);
      const payload = response.data;
      if (payload.status === "success") {
        toast.success("Product Unsubscribed!");
        fetchUserSubscribedProds();
      }
    } catch {
      // handle error
    }
    setSubscribeLoading(false);
  };

  const preeBookOrder = async (prodId) => {
    if (!userContext?.JWT) {
      navigate("/login");
      return;
    }
    if (thisProdPreBooked && !preBookData?.isRefundable) return;
    try {
      if (thisProdPreBooked && preBookData?.isRefundable) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const refundRes = await refundPreBookProd(preBookedId);
            if (refundRes.status === "success") {
              toast.success("Pre-Book Order has been Cancel!");
              setThisProdPreBooked(false);
            }
            return;
          }
        });
      } else {
        try {
          setSubscribeLoading(true);
          const response = await preBookProd(prodId);
          window.open(response, "_parent");
          // if (payload.status === "success") {
          //   toast.success("Product Subscribed!");
          //   fetchUserSubscribedProds();
          // }
        } catch {
          // handle error
        }
      }

      // if (payload.status === "success") {
      //   toast.success("Product Subscribed!");
      //   fetchUserSubscribedProds();
      // }
    } catch (error) {
      console.log("error ", error);
    }
  };

  const storeProductNotification = async (productId) => {
    try {
      setSubscribeLoading(true);
      const response = await storeProdNotificationService(productId);
      const payload = response.data;

      if (payload.status === API_RESPONSE_STATUS.SUCCESS) {
        toast.success(payload.message);
        fetchUserSubscribedProds();
      } else {
        toast.error(payload.message);
      }
      setSubscribeLoading(false);
      // reset history
      window.history.replaceState({}, document.title);
    } catch {
      toast.error(t("writeToUs.error"));
    }
  };

  const fetchUserSubscribedProds = async () => {
    if (!userContext?.JWT) return;

    try {
      const response = await getUsersSubProds();
      const payload = response.data;
      if (payload.status === "success") {
        setUserSubscribed(payload?.subscribedProducts ?? []);
        setUserPreBooked(payload?.preBooked ?? []);
      }
    } catch {
      // handle error
    }
  };

  return (
    <div className="product-card">
      <Link
        className="product-img cursor-pointer"
        // to={{
        //   pathname: `/product/${productSlug}`,
        // }}
        // state={{ ...from }}
        onClick={() => handleLinkClick()}
      >
        <div className="tags-container">
          <div className="tags">
            {/* {bestSeller && (  */}
            {/* <div className="tag-item tag-best-seller">
              <img width="35px" src={flag} style={{ width: "35px" }} />
            </div> */}
            {/* )} */}
          </div>
          <div
            className="tag-fav"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              handleAddToWishlist(id, wishlist);
            }}
          >
            <StyledRating
              name="wishlist"
              title={wishlist ? t("removeFromWishlist") : t("product-page.add-to-wishlist")}
              max={1}
              defaultValue={wishlist ? 1 : 0}
              icon={<FavoriteIcon fontSize="inherit" />}
              emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
            />
          </div>
        </div>

        <img src={productImg ?? NothingImage} alt={productImg ?? `image-${id}`} />
      </Link>
      <div className="product-info">
        {/*  onClick={() => handleScrollToTop()} */}
        <p className="prod-item prod-name cursor-pointer productNamehover" onClick={() => handleLinkClick()}>
          {productName}
        </p>
        {/* <p className="prod-item prod-desc mb-2 mb-lg-3">
                    {productDescription?.length ? productDescription : ""}
                </p> */}
        <div className="d-flex flex-column justify-content-between flex-lg-row ">
          <div className="mt-0 mt-lg-2" style={{ width: "46%" }}>
            <Rating style={{ width: "80%", marginBottom: "10px" }} name="product-rating" readOnly value={productRating} size="small" />

            {/* <p className="prod-item prod-price mt-lg-0 d-flex text-secondary mb-1"> */}
            {classification !== PRODUCT_CLASSIFICATION.COMING_SOON && classification !== PRODUCT_CLASSIFICATION.PRE_BOOKED && (
              <>
                {maxMRPPrice - getDiscount.discountPrice > 0 && (
                  <div className="text-secondary d-flex  " style={{ fontSize: "11px" }}>
                    <span>M.R.P.:</span>
                    <span className=" originalMrp ">
                      <s> {currencyFormat(maxMRPPrice)}</s>
                    </span>
                  </div>
                )}
                <p className="prod-item prod-discount-price mt-1 mt-lg-1 mb-2 ">{currencyFormat(getDiscount.discountPrice)}</p>
              </>
            )}
            {classification === PRODUCT_CLASSIFICATION.PRE_BOOKED && (
              <>
                <p className="prod-item prod-discount-price mt-1 mt-lg-1 mb-2 ">{currencyFormat(preBookData?.amount)}</p>
              </>
            )}
            {/* </p> */}
          </div>
          <div className="d-flex  product-emi-offers" style={{ flexDirection: "column-reverse" }}>
            <div className="emi-offer">
              <span>*{t("EMIAvailable")}</span>
              <span>
                *{t("Checkbank")} {t("offer")} {isMobile && <br />}{" "}
              </span>
            </div>
            {classification !== PRODUCT_CLASSIFICATION.COMING_SOON && classification !== PRODUCT_CLASSIFICATION.PRE_BOOKED ? (
              <>
                {getDiscount.discountPercentage > 0 ? (
                  <div className="tag-item tag-discount  mt-lg-3 mr-lg-1" style={{ width: "61%", padding: "5px" }}>
                    <span>{getDiscount.discountPercentage}% off</span>
                  </div>
                ) : null}
              </>
            ) : (
              <></>
            )}
          </div>
          {/* <div>
          </div> */}
        </div>
      </div>
      <div className="prod-buy" style={{ height: `${isMobile && "4.5rem"}` }}>
        {classification === PRODUCT_CLASSIFICATION.COMING_SOON ? (
          <button className="prod-buy-btn" disabled={subscribeLoading} onClick={() => notifyUserInit(id)}>
            <>{thisProdSubs ? t("product-page.unsubscribe", "Unsubscribe") : t("product-page.notifyme")}</>
            {subscribeLoading && <span className="spinner-border spinner-border-sm align-text-bottom ms-2" role="status" aria-hidden="true"></span>}
          </button>
        ) : (
          <>
            {classification === PRODUCT_CLASSIFICATION.PRE_BOOKED ? (
              <button className="prod-buy-btn" disabled={subscribeLoading} onClick={() => preeBookOrder(id)}>
                <>{thisProdPreBooked ? "pre booked" : "Pre Book Now"}</>
                {subscribeLoading && <span className="spinner-border spinner-border-sm align-text-bottom ms-2" role="status" aria-hidden="true"></span>}
              </button>
            ) : (
              <button className="prod-buy-btn" onClick={() => handleBuyNow(id)} disabled={isLoadingBuyNow}>
                {t("product-page.buy-now")}
                {isLoadingBuyNow && <span className="spinner-border spinner-border-sm align-text-bottom ms-2" role="status" aria-hidden="true"></span>}
              </button>
            )}
          </>
        )}
        {classification !== PRODUCT_CLASSIFICATION.COMING_SOON && classification !== PRODUCT_CLASSIFICATION.PRE_BOOKED && (
          <>
            {!cart ? (
              <>
                <ShoppingCartOutlinedIcon fontSize="large" className="CardShoppingCartIcon" onClick={() => prepareAddToCart(id)} disabled={isLoadingBuyNow} />
                {isLoadingBuyNow && <span className="spinner-border spinner-border-sm align-text-bottom ms-2 " style={{ height: "13px" }} role="status" aria-hidden="true"></span>}
              </>
            ) : (
              <ShoppingCartRoundedIcon fontSize="large" className="CardShoppingCartIcon" onClick={() => handleRemoveFromCart(id)} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
