import React from "react";
import { Link } from "react-router-dom";

//CSS
import "./BreadCrumbs.css";

//Images
import arrowRightGrey from "../../assets/vector/arrow_right_grey.svg";

const BreadCrumbs = ({ data }) => {
  // console.log(data);
  return (
    <>
      <div className="slug-URL-Container">
        <span className="slug-URL">
          {data.map((item, index) => (
            <div key={index}>
              {!item.url ? (
                <>
                  <span className={`${item.isActive ? "text-dark" : ""} slugLink`} key={index}>
                    {item.text}
                  </span>
                </>
              ) : (
                <>
                  <Link className={`${item.isActive ? "text-dark" : ""} slugLink`} to={item.url} key={index}>
                    {item.text}
                  </Link>
                  {" > "}
                  {/* <img src={arrowRightGrey} alt="" /> */}
                </>
              )}
            </div>
          ))}
        </span>
      </div>
    </>
  );
};

export default BreadCrumbs;
