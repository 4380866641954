//Dependencies
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import SegmentIcon from "@mui/icons-material/Segment";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { CircularProgress, Drawer, Paper } from "@mui/material";
//CSS
import "../ProductCategory/ProductCategory.css";

//Components
import { getProductsFromBrand, getSearchedProduct, getValuesForDynamicFiltersForBrands } from "../../api/Product";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useProductCtx from "../../hooks/useProductCtx";
import ProductCard from "../../components/Cards/ProductCard";
import Pagination from "@mui/material/Pagination";
//Utils
import { PRODUCT_CLASSIFICATION } from "../../utils/Constants";
import { useTranslation } from "react-i18next";

import { useMediaQuery } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import useUserCtx from "../../hooks/useUserCtx";
import DrawerSection from "../ProductCategory/DrawerSection";
import FilterListData from "../../components/FilterListData";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import useSiteCtx from "../../hooks/useSiteCtx";
import SEO from "../../components/SEO/SEO";

// Component definition

const ProductBrands = () => {
  const isMobile = useMediaQuery("(max-width:568px)");

  // translation
  const { t } = useTranslation();
  const { userWishlist, cartArray } = useUserCtx();

  const [minMaxPrice, setMinMaxPrice] = useState({ highest: 100, lowest: 0 });
  const [priceRange, setPriceRange] = useState([minMaxPrice.lowest, minMaxPrice.highest]);
  const [step, setStep] = useState(1);

  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filterSelected, setFilterSelected] = useState([]);
  const [totalProducts, setTotalProducts] = useState(1);
  const [toggleFilter, setToggleFilter] = useState(false);
  const [sorting, setSorting] = useState("");
  const [filterListData, setFilterListData] = useState([]);

  const { width } = useWindowDimensions();

  const matches = width >= 768;
  const nav = useNavigate();

  const { searchedProduct, setSearchedProduct, currentPage, setCurrentPage } = useProductCtx();
  const productsPerPage = 12;

  const location = useLocation();
  const { slug } = useParams();

  const { homePageSections } = useSiteCtx();

  const [showTopPagePosters, setShowTopPagePosters] = useState({});

  const { seoData } = useSiteCtx();
  const scrollToProductCard = () => {
    // document.querySelector(".order_Page_Right_res").scrollIntoView();
  };

  const handleChange = (event, newValue) => {
    setPriceRange(() => newValue);
  };

  function handlePriceRangeSubmit() {
    setFilterSelected((prev) =>
      prev.some((e) => e.type === "price.mop")
        ? [...prev.filter((e) => e.type !== "price.mop"), { type: "price.mop", searchQ: priceRange.join("-") }]
        : [...prev, { type: "price.mop", searchQ: priceRange.join("-") }]
    );
    isMobile && handleClose();
    scrollToProductCard();
  }

  function onPriceClick(range) {
    if (range[0] === priceRange[0] && range[1] === priceRange[1]) {
      setPriceRange([minMaxPrice.lowest, minMaxPrice.highest]);
      setFilterSelected((prev) => [...prev.filter((e) => e.type !== "price.mop"), { type: "price.mop", searchQ: `${minMaxPrice.lowest}-${minMaxPrice.highest}` }]);
    } else {
      setPriceRange(range);
      setFilterSelected((prev) =>
        prev.some((e) => e.type === "price.mop")
          ? [...prev.filter((e) => e.type !== "price.mop"), { type: "price.mop", searchQ: range.join("-") }]
          : [...prev, { type: "price.mop", searchQ: range.join("-") }]
      );
    }
    isMobile && handleClose();
    scrollToProductCard();
  }

  function handlePriceReset() {
    setPriceRange([minMaxPrice.lowest, minMaxPrice.highest]);
    isMobile && handleClose();
    scrollToProductCard();
  }

  const handleOpen = (event) => {
    if (isMobile) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAll2 = (event, resp) => {
    setCurrentPage(1);
    setFilterSelected((filterObj) => {
      if (filterObj.some((filter) => filter.type === resp.type && filter.id === resp.id)) {
        return [...filterObj.filter((filter) => filter.id !== resp.id)];
      }
      return [...filterObj, resp];
    });
  };

  const handleSingleFilterSelection = (resp) => {
    setCurrentPage(1);
    setFilterSelected((filterObj) => {
      if (filterObj.some((filter) => filter.type === resp.type)) {
        if (filterObj.some((filter) => filter.id !== resp.id)) return [...filterObj.filter((filter) => filter.type !== resp.type), resp];
        else return [...filterObj.filter((filter) => filter.type !== resp.type)];
      }
      return [...filterObj, resp];
    });
  };

  useEffect(() => {
    let topBanners = homePageSections.find((section) => section.sectionType === "static_poster");
    if (!topBanners) return;
    var arr2 = [];
    const updatedProducts = topBanners.products.filter((product) => product.title.includes("_"));
    arr2.push(...updatedProducts);
    var category = location.search.split("=")[1];

    const brandName = location.pathname.split("/")[2];
    const filteredArr = [];
    arr2.forEach((item) => {
      const titleParts = item.title.split("_");
      if (titleParts[1] === brandName.toString() && titleParts[2] === category) {
        filteredArr.push(item);
      }
    });
    setShowTopPagePosters(filteredArr[0]);
  }, [homePageSections, location]);

  const generateDynamicFilterArray = useCallback((objValues) => {
    const values = {
      ram: "GB",
      internal_storage: "GB",
      display_size: "Inches",
      screen_size: "Inches",
      warranty: "Year",
      total_playback_time: "Hours",
      refresh_rate: "Hz",
    };
    function checkIfAlreadyPresent(str, value) {
      return str?.toString()?.replace(/[0-9]/, "ig")?.trim()?.toLowerCase() === value?.toLowerCase();
    }
    setFilterListData(() => {
      const arr = FilterListData["all"];
      if (!objValues) return;
      const arr2 = Object.entries(objValues).map((entries) => {
        if (entries[0].toString() === "brand")
          return {
            filter_heading: entries[0].toString(),
            filter_data: entries[1].map((e, index) => ({
              type: `${entries[0]}`,
              data: e,
              searchQ: e,
              id: `${entries[0]}-${e}`,
            })),
          };
        else if (entries[0].toString() === "price")
          return {
            filter_heading: entries[0].toString(),
            filter_data: entries[1].map((e, index) => {
              if (index !== entries[1].length - 1)
                return {
                  type: "price.mop",
                  data: e.data,
                  searchQ: e.searchQ,
                  id: `${entries[0]}-${e.data}`,
                };
              else return null;
            }),
          };
        // Dynamic Filters
        else {
          // if (entries[1][0] === "__") {
          //   entries[1].shift(); // Remove the first element from entries[1]
          // }
          // if (entries[1][0] === "-") {
          //   entries[1] = entries[1].slice(1); // Remove the first element from entries[1]
          // }
          return {
            filter_heading: entries[0].toString().split("_").join(" "),
            filter_data: entries[1].map((e, index) => ({
              type: `productInfo.${entries[0]}`,
              data: values[entries[0]?.toString()] && checkIfAlreadyPresent(e, values[entries[0]?.toString()]) ? `${e} ${values[entries[0].toString()]}` : e,
              searchQ: e,
              id: `${entries[0]}_${index}`,
              dynamic: true,
            })),
          };
        }
      });
      const priceIndex = arr2.findIndex((item) => item.filter_heading === "price");

      // Move the element with filter_heading === "price" to the end
      if (priceIndex !== -1) {
        const priceElement = arr2.splice(priceIndex, 1)[0];
        arr2.push(priceElement);
      }

      return arr.concat(...arr2);
    });
    const { lowest, highest } = objValues.price[objValues.price.length - 1] ?? { lowest: 0, highest: 0 };
    setMinMaxPrice((prev) => ({ ...prev, lowest, highest }));
    setStep(() => Math.ceil((highest - lowest) / 100));
    setPriceRange((prev) => [lowest, highest]);
  }, []);

  useEffect(() => {
    setFilterSelected([]);
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    if (location.search) {
      var category = location.search.split("=")[1];
      getValuesForDynamicFiltersForBrands(slug + "|" + category).then((res) => {
        if (res.status === "success") generateDynamicFilterArray(res.obj);
      });
    }
  }, [slug]);

  useEffect(() => {
    setLoading(true);
    const params = new URLSearchParams(location.search);
    if (sorting === "descending") {
      params.append("sort", "descending");
    }
    if (sorting === "ascending") {
      params.append("sort", "ascending");
    }
    params.append("limit", productsPerPage);
    params.append("page", currentPage);
    filterSelected?.length > 0 &&
      filterSelected.forEach((value) => {
        if (value.type === "price.mop") {
          params.append(`${value.type}`, value.searchQ);
        }
        // if (value.type?.toString()?.toLowerCase() === "Category"?.toString()?.toLowerCase()) {
        //     params.append("hierarchyL2", value.data);
        // }
        if (value.type?.toString()?.toLowerCase() === "hierarchyL3"?.toString()?.toLowerCase()) {
          params.append("hierarchyL3", value.data);
        }
        if (value.type?.toString()?.toLowerCase() === "rating"?.toString()?.toLowerCase()) {
          params.append("rating", value.data);
        }
        if (value?.dynamic) {
          params.append("dynamic", true);
          params.append(`${value.type}`, value.searchQ.toString().trim());
        }
      });
    getProductsFromBrand(slug, params).then((res) => {
      const products = res.products.filter((prod) => prod.classification !== PRODUCT_CLASSIFICATION.TEMP_HIDDEN);
      setSearchedProduct({
        loaded: true,
        products,
        no_of_products: res.total_products,
      });
      setTotalProducts(res.total_products);
      const targetElement = document.querySelector(".mainContainerWrapper");

      // Scroll to the element if found
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
      setLoading(false);
    });
  }, [filterSelected, setSearchedProduct, currentPage, location, sorting, slug]);

  const handlePageChange = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
    scrollToProductCard();
  };

  // POPUP
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClosePOP = (event, sortValue) => {
    // if (sortValue) {
    setSorting(sortValue);
    setCurrentPage(1);
    // }
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  function capitalizeWords(sentence) {
    const words = sentence.split(" ");

    const capitalizedWords = words.map((word) => {
      const firstLetter = word.charAt(0).toUpperCase();
      const restOfWord = word.slice(1);
      return firstLetter + restOfWord;
    });

    const capitalizedSentence = capitalizedWords.join(" ");

    return capitalizedSentence;
  }

  const brandName = location.search.split("?")[1].split("=")[1];
  const decodedBrandName = decodeURIComponent(brandName);

  const breadCrumData = [
    {
      url: "/",
      text: "Consumer Electronics",
    },
    {
      url: `/Category=${location.search.split("?")[1].split("=")[1]}`,
      text: decodedBrandName,
    },
    {
      isActive: true,
      text: location.pathname.split("/")[2],
    },
  ];

  const headingName = useMemo(() => {
    return capitalizeWords(slug) + " Results";
  }, [slug]);

  return (
    <>
      <SEO seoData={seoData} />
      <div
        className="mainContainerWrapper"
        style={{
          marginTop: "var(--pageMarginTop)",
          width: "100%",
        }}
      >
        {showTopPagePosters?.imgUrl && (
          <Link className="all-categories-banner offer-banners" to={showTopPagePosters.targetUrl}>
            <img
              src={showTopPagePosters.imgUrl}
              loading="lazy"
              alt={showTopPagePosters.title}
              title="My Offers"
              style={{ objectFit: `${isMobile && "cover"}`, minHeight: "90px", maxWeight: "450px", cursor: "pointer" }}
            />
          </Link>
        )}

        {/* <div className="all-categories-section-1">
          {filterListData?.find((filterData) => filterData.filter_heading === "price")?.filter_data?.length ? (
            <div id="all-mid-heading">
              <span>{t("ShopbyBudget")}</span>
            </div>
          ) : null}
          {loading && !isMobile ? (
            <div className="loader-div" style={{ height: "47px", margin: "0px" }}>
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <>
              {isMobile ? (
                <>
                  {filterListData.map((filterData, index) =>
                    filterData.filter_heading === "price" ? (
                      <>
                        <div className="all-offer-comp">
                          {filterData.filter_data.slice(0, 2).map(
                            (filter, i) =>
                              filter && (
                                <div
                                  className="all-offer-items"
                                  onClick={() => {
                                    onPriceClick(filter.searchQ.split("-").map((data) => data));
                                  }}
                                  key={`price-display-1-${index}-${i}`}
                                  data-priceselected={priceRange[0] === filter.searchQ.split("-")[0] && priceRange[1] === filter.searchQ.split("-")[1] ? "priceselected" : "false"}
                                >
                                  {filter.data.split("-").map((data) => (
                                    <span>{data}</span>
                                  ))}
                                </div>
                              )
                          )}
                        </div>
                        <div className="all-offer-comp">
                          {filterData.filter_data.slice(2).map(
                            (filter, i) =>
                              filter && (
                                <div
                                  className="all-offer-items"
                                  onClick={() => {
                                    onPriceClick(filter.searchQ.split("-").map((data) => data));
                                  }}
                                  key={`price-display-2-${index}`}
                                  data-priceselected={priceRange[0] === filter.searchQ.split("-")[0] && priceRange[1] === filter.searchQ.split("-")[1] ? "priceselected" : "false"}
                                >
                                  {filter.data.split("-").map((data) => (
                                    <span>{data}</span>
                                  ))}
                                </div>
                              )
                          )}
                        </div>
                      </>
                    ) : null
                  )}
                </>
              ) : (
                <div className="all-offer-comp">
                  {filterListData?.length > 0 &&
                    filterListData.map((filterData, index) =>
                      filterData.filter_heading === "price"
                        ? filterData.filter_data.map(
                            (filter, i) =>
                              filter && (
                                <div
                                  onClick={() => {
                                    onPriceClick(filter.searchQ.split("-").map((data) => data));
                                  }}
                                  className="all-offer-items"
                                  data-priceselected={priceRange[0] === filter.searchQ.split("-")[0] && priceRange[1] === filter.searchQ.split("-")[1] ? "priceselected" : "false"}
                                  key={`price-display-3-${index}-${i}`}
                                >
                                  {filter.data.split("-").map((data) => (
                                    <span>{data}</span>
                                  ))}
                                </div>
                              )
                          )
                        : null
                    )}
                </div>
              )}
            </>
          )}
        </div> */}
        <div className="breadCrumWrapper">
          <BreadCrumbs data={breadCrumData} />
        </div>
        <div
          className={`main_Content_Show ${toggleFilter && !matches ? "main_Content_Hide" : ""}`}
          style={{
            marginTop: "1rem",
          }}
        >
          {loading ? (
            <div className="loader-div">
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <div className="desk_Page_Wrapper_res">
              {isMobile ? (
                <div>
                  <Drawer varaint="temporary" anchor="right" open={Boolean(anchorEl)} onClose={handleClose} PaperProps={{ style: { zIndex: 1200 } }}>
                    <DrawerSection
                      isMobile={isMobile}
                      onClick={handleClose}
                      value={priceRange}
                      handleChange={handleChange}
                      filterListData={filterListData}
                      handleAll2={handleAll2}
                      filterSelected={filterSelected}
                      handlePriceRangeSubmit={handlePriceRangeSubmit}
                      minMaxPrice={minMaxPrice}
                      sliderStep={step}
                      handlePriceReset={handlePriceReset}
                      headingName={headingName}
                      priceRange={priceRange}
                    />
                  </Drawer>
                </div>
              ) : (
                <DrawerSection
                  filterListData={filterListData}
                  isMobile={isMobile}
                  value={priceRange}
                  handleChange={handleChange}
                  handleAll2={handleAll2}
                  filterSelected={filterSelected}
                  handlePriceRangeSubmit={handlePriceRangeSubmit}
                  minMaxPrice={minMaxPrice}
                  sliderStep={step}
                  handlePriceReset={handlePriceReset}
                  headingName={headingName}
                  priceRange={priceRange}
                />
              )}

              <div className="order_Page_Right_res" style={{ padding: "0px 22px 0px 10px" }}>
                {searchedProduct.no_of_products > 0 ? (
                  <>
                    <div className="right-wrapper">
                      <div className="SpotLight_res">
                        <div>
                          <span>{t("Spotlight")}</span>
                        </div>
                        <div className="SpotLight_res-items" onClick={handleOpen}>
                          {isMobile ? <FilterAltOutlinedIcon /> : <SegmentIcon fontSize="large" />}
                          {isMobile ? (
                            <span>{t("Filter")}</span>
                          ) : (
                            <span
                              ref={anchorRef}
                              id="composition-button"
                              aria-controls={open ? "composition-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={handleToggle}
                              style={{ cursor: "pointer" }}
                            >
                              {t("sortAndFilter.sort-by")}
                            </span>
                          )}
                          <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-start" transition disablePortal style={{ zIndex: "100000" }}>
                            {({ TransitionProps, placement }) => (
                              <Grow
                                {...TransitionProps}
                                style={{
                                  transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
                                }}
                              >
                                <Paper>
                                  <ClickAwayListener onClickAway={handleClosePOP}>
                                    <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button" onKeyDown={handleListKeyDown}>
                                      <MenuItem onClick={(event) => handleClosePOP(event, "")}>
                                        <input type="radio" style={{ accentColor: "orange" }} checked={sorting === "" && true} /> <span>{t("default")}</span>
                                      </MenuItem>
                                      <MenuItem onClick={(event) => handleClosePOP(event, "ascending")}>
                                        <input type="radio" style={{ accentColor: "orange" }} checked={sorting === "ascending" && true} /> <span>{t("sortAndFilter.price-ascending")}</span>
                                      </MenuItem>
                                      <MenuItem onClick={(event) => handleClosePOP(event, "descending")}>
                                        <input type="radio" style={{ accentColor: "orange" }} checked={sorting === "descending" && true} /> <span>{t("sortAndFilter.price-descending")}</span>
                                      </MenuItem>
                                    </MenuList>
                                  </ClickAwayListener>
                                </Paper>
                              </Grow>
                            )}
                          </Popper>
                        </div>
                      </div>
                      <div className={"Product_Category_Container_res"}>
                        {searchedProduct.products.map((product, index) => (
                          <ProductCard
                            key={`product-${index}`}
                            id={product._id}
                            productImg={product.images?.length > 0 ? product.images[0] : null}
                            productName={product.title}
                            productDescription={product?.description?.length > 0 ? product?.description[0]?.split(" ")?.slice(0, 18)?.join(" ") : null}
                            productRating={product?.rating ?? 0}
                            productPriceObj={product.price}
                            productDiscountObj={product.discount}
                            productSlug={product.slug}
                            wishlist={userWishlist.wishlist_items?.some((obj) => obj._id?.toString() === product._id?.toString())}
                            cart={cartArray?.no_of_carts > 0 && cartArray?.cart.some((obj) => obj._id?.toString() === product._id?.toString())}
                            classification={product?.classification}
                            preBookData={product?.preBook}
                          />
                        ))}
                      </div>
                      <div className="pagination-con">
                        <Pagination
                          sx={{
                            color: "primary.main",
                          }}
                          page={currentPage}
                          defaultPage={1}
                          onChange={handlePageChange}
                          count={Math.ceil(totalProducts / productsPerPage)}
                          siblingCount={isMobile ? 0 : 1}
                          variant="outlined"
                          shape="rounded"
                          size="large"
                        />
                      </div>
                      <div className="pagination_Container"></div>
                    </div>
                  </>
                ) : (
                  <div className="empty_order_sec_res">
                    <p className="empty_order_text_res">{t("sortAndFilter.no-product")}</p>
                    {location.search && location.search.includes("hierarchyL2") ? (
                      <button type="submit" className="submit-button" onClick={() => nav(`/Category=${location.search.split("?")[1].split("=")[1]}`)}>
                        <p>View All {`${location.search.split("?")[1].split("=")[1].split("%20").join(" ")}s`}</p>
                      </button>
                    ) : (
                      <button type="submit" className="submit-button" onClick={() => nav("/")}>
                        <p>{t("back-to-home")}</p>
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductBrands;
