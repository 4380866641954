import React from "react";
import disableOLINEOLogo from "../assets/png/disableOLINEOLogo.png"; // Replace with your actual image path
import logo from "../assets/png/olineoLogo.png"; // Replace with your actual image path
import congratulations from "../assets/png/congratulations.png"; // Replace with your actual image path
import star from "../assets/png/star.png"; // Replace with your actual image path
import disableStar from "../assets/png/disableStar.png"; // Replace with your actual image path
import disablecongratulations from "../assets/png/disablecongratulations.png"; // Replace with your actual image path
import { currencyFormat } from "../utils";
import { useNavigate } from "react-router-dom";

function Coupon({ text, value, status, isMobile }) {
  const navigate = useNavigate();
  return (
    <div className={`${status === "active" ? "couponeCard" : "usedCouponCard"}`}>
      <div className="coupnImage">
        <div className="d-flex">
          {status === "active" ? <img src={logo} /> : <img src={disableOLINEOLogo} />}

          <div className="startDiv">
            <div className="startChildDiv">{status === "active" ? <img src={star} /> : <img src={disableStar} />}</div>

            <span className="couponText">{text}</span>
            <div className="startChildDiv">{status === "active" ? <img src={star} /> : <img src={disableStar} />}</div>
          </div>
        </div>
        <div className="middleLine"></div>
        <div className="cashBack">
          {status === "active" ? <img src={congratulations} /> : <img src={disablecongratulations} />}
          <p>
            Cash Back Of <span style={{ fontStyle: "italic" }}>{currencyFormat(value)} *</span>
          </p>
          <div>
            {status === "active" ? (
              <span className="statusDiv cursor-pointer" onClick={() => navigate("/")}>
                <span className="statusDivactive">Active</span>
              </span>
            ) : (
              <span className="statusDivclaimed ">Claimed</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Coupon;
