import React, { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../pages/Dashboard/styles/dashAddproduct.css";
import { useState } from "react";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import useAdminCtx from "../../hooks/useAdminCtx";
import {
  addNewStaticDealsSection,
  addStaticDealsSectionImages,
  deleteDealsImages,
  getHomePageSections,
  updateStaticDealsSection,
  updateStaticDealsSectionImage,
} from "../../api/AdminApis/HomePageSections";
import { Button, Card } from "react-bootstrap";

const StaticDeals = () => {
  const [id, setId] = useState(null);
  const location = useLocation();
  const { allHomePageSections, setAllHomePageSections } = useAdminCtx();
  const navigate = useNavigate();
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [deleteImage, setDeleteImage] = useState([]);
  const [banners, setBanners] = useState([
    {
      title: "",
      targetUrl: "",
      image: null,
      imageUrl: null,
      imgKey: null,
    },
  ]);
  const [productData, setProductData] = useState({
    sectionHeading: "",
    priority: null,
    isStaticMobileViewBanner: false,
  });

  const addMoreBtn = useCallback(() => {
    setBanners((prev) => [
      ...prev,
      {
        title: "",
        targetUrl: "",
        image: null,
        imageUrl: null,
        imgKey: null,
      },
    ]);
  }, []);

  const handleRemoveCard = (index) => {
    if (window.confirm("Sure?")) {
      if (banners[index].image.slice(0, 4) === "http") {
        setDeleteImage((prev) => [...prev, banners[index].image]);
        setBanners((prev) => prev.filter((banner, i) => i !== index));
      }
    }
  };

  function deleteSelectedImage(index) {
    if (window.confirm("Sure?")) {
      if (banners[index].image.slice(0, 4) === "http") {
        setDeleteImage((prev) => [...prev, banners[index].image]);
        setBanners((prev) => {
          let banners = [...prev];
          banners[index].image = null;
          banners[index].imageUrl = null;
          return [...banners];
        });
      }
    }
  }

  useEffect(() => {
    getHomePageSections((err, res) => {
      if (err) toast.error("Unable to Fetch the Sections properly");
      setAllHomePageSections(res.sections);
    });
  }, []);

  useEffect(() => {
    if (location.state?.data) {
      setProductData((prev) => ({
        ...prev,
        sectionHeading: location.state?.data?.sectionHeading ?? "",
        priority: location.state?.data?.priority ?? null,
        isStaticMobileViewBanner: location.state?.data?.sectionType === "static_deals_mobile" ? true : false,
      }));
      setBanners(() => {
        let totalBanners = location?.state?.data?.products;
        totalBanners.forEach((banner) => (banner.imageUrl = banner.image));
        return [...totalBanners];
      });
      setId(() => location?.state?.data?._id ?? null);
    }
  }, [location.state?.data]);

  const handleFileUpload = (e, index) => {
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.readyState === 2) {
        setBanners((prev) => {
          let existingBanners = [...prev];
          existingBanners[index].image = file;
          existingBanners[index].imageUrl = reader.result;
          return [...existingBanners];
        });
      }
      reader.onerror = function () {
        toast.error("Something went wrong");
      };
    };
  };

  const handleChange = (e, index) => {
    setBanners((prev) => {
      let existingBanners = [...prev];
      if (e.target.name === "title") existingBanners[index].title = e.target.value;
      if (e.target.name === "targetUrl") existingBanners[index].targetUrl = e.target.value;
      return [...existingBanners];
    });
  };

  const handleSubmit = () => {
    setSubmitBtnLoading(true);

    const obj = {
      sectionHeading: productData.sectionHeading,
      sectionType: productData.isStaticMobileViewBanner ? "static_deals_mobile" : "static_deals",
    };

    if (!id) {
      const bannerDetails = [];
      banners.forEach((banner) => {
        bannerDetails.push({ title: banner.title, targetUrl: banner.targetUrl, imgKey: banner.imgKey });
      });
      obj.products = bannerDetails;
      obj.priority = !productData.priority ? (allHomePageSections.length === 0 ? 1 : allHomePageSections[allHomePageSections.length - 1].priority + 1) : productData.priority;
      addNewStaticDealsSection(obj).then((res) => {
        if (res.status === 201) {
          const id = res.data.data.id;
          const images = banners.map((banner) => banner.image);
          let isSuccess;
          addStaticDealsSectionImages(id, images).then((res2) => {
            isSuccess = res2.status;
            if (isSuccess === 200) {
              navigate("../admin-home-page");
              return toast.success("Successfully added");
            }
          });
        } else {
          return toast.error("Something went wrong!");
        }
        setSubmitBtnLoading(false);
      });
    } else {
      obj.products = banners.map((banner) => ({ title: banner.title, targetUrl: banner.targetUrl, image: banner.image.slice(0, 4) === "http" ? banner.image : "", imgKey: banner.imgKey }));
      if (location.state.data.priority === productData.priority) {
        obj.priority = location.state.data.priority;
      } else {
        obj.priority = productData.priority;
      }
      updateStaticDealsSection(id, obj).then((res) => {
        if (res.data.status === "success") {
          const images = banners.map((banner) => (banner.image?.slice(0, 4) !== "http" ? banner.image : null));
          const indexes = [];
          banners.forEach((banner, index) => {
            if (banner.image?.slice(0, 4) !== "http") indexes.push(index);
          });
          if (indexes.length > 0) {
            updateStaticDealsSectionImage(id, images, indexes).then((res2) => {
              if (res2.data.status === "success") {
                navigate("../admin-home-page");
                return toast.success("Successfully Updated!");
              }
            });
          }
          // if (deleteImage.length > 0) {
          //   deleteDealsImages(id, deleteImage).then(() => console.log("Success"));
          // }
          if (indexes.length <= 0) {
            navigate("../admin-home-page");
            return toast.success("Successfully Updated!");
          }
        } else {
          toast.error("Something went wrong");
        }
        setSubmitBtnLoading(false);
      });
    }
  };

  const handleCheckboxChange = (e) => {
    setProductData({ ...productData, [e.target.name]: e.target.value });
  };
  return (
    <>
      <div>
        <h2>Add Static Deals/Offers Section</h2>
      </div>

      <Form className="mt-4" encType="multipart/form-data">
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Section Heading</Form.Label>
          <input
            className="form-control"
            type="text"
            name="sectionHeading"
            value={productData.sectionHeading}
            onChange={(e) =>
              setProductData((prev) => ({
                ...prev,
                sectionHeading: e.target.value,
              }))
            }
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            label="Static Mobile View Banner"
            name="isStaticMobileViewBanner"
            checked={productData.isStaticMobileViewBanner}
            onChange={(e) => {
              const { name, checked } = e.target;
              handleCheckboxChange({ target: { name, value: checked } });
            }}
          />{" "}
          {productData.isStaticMobileViewBanner ? <p>Size - 250px X 150px</p> : <p>Size - 250px X 50px</p>}
        </Form.Group>
        {banners.map((b, index) => (
          <Card className="mb-3" key={index}>
            <Card.Header style={{ display: "flex", justifyContent: "space-between" }}>
              <Card.Title>Deals/Offer-{index + 1} </Card.Title>
              <Button variant="outline-danger" onClick={() => handleRemoveCard(index)}>
                Remove
              </Button>
            </Card.Header>
            <Card.Body>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Product Title *</Form.Label>
                <Form.Control type="text" name="title" value={b.title} onChange={(e) => handleChange(e, index)} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                <Form.Label>Target URL *</Form.Label>
                <Form.Control type="text" name="targetUrl" value={b.targetUrl} onChange={(e) => handleChange(e, index)} />
              </Form.Group>
              <Form.Group controlId="formFile" className="mb-3">
                <label htmlFor={`staticBanner-upload-${index}`} className="custom-fileSelect">
                  Select Deals/Offer Image *
                </label>
                <input type="file" name="Gallery Images" id={`staticBanner-upload-${index}`} accept=".jpg, .jpeg, .png .webp" onChange={(e) => handleFileUpload(e, index)} hidden />
              </Form.Group>
              {b.imageUrl && (
                <>
                  <Form.Label className="w-100">Preview</Form.Label>
                  <div className="mx-auto d-flex flex-column gap-2">
                    <img src={b.imageUrl} width="400" alt={`image-${index}`} className="mx-auto" />
                    <Button variant="danger" className="mx-auto" style={{ width: "30%" }} onClick={() => deleteSelectedImage(index)}>
                      Remove Image
                    </Button>
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
        ))}

        <Button variant="outline-primary" onClick={addMoreBtn}>
          Add More
        </Button>
        <Form.Group className="mb-3" controlId="formFile">
          <Form.Label>Order (Optional)</Form.Label>
          <Form.Control
            type="number"
            name="priority"
            value={productData?.priority}
            onChange={(e) =>
              setProductData((prev) => ({
                ...prev,
                priority: e.target.value,
              }))
            }
          />
        </Form.Group>
      </Form>

      <div className="my-5">
        <button disabled={submitBtnLoading} type="submit" className="submit-button" onClick={handleSubmit}>
          <p>{"Submit"}</p>
        </button>
      </div>
    </>
  );
};

export default StaticDeals;
